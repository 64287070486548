<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="
        text-dark-75
        font-weight-bolder
        text-hover-primary
        mb-1
        font-size-lg
        d-flex
        flex-row
        align-items-center
      "
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <validation-observer ref="formKelas">
      <b-form @submit.prevent="submitFormKelas" @reset="onReset">
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ this.idKelas ? "Ubah" : "Buat" }} Kelas
              </span>
            </h3>
            <div class="card-toolbar">
              <button
                type="submit"
                class="btn btn-success font-weight-bolder font-size-sm mr-2"
              >
                Simpan
              </button>
              <span
                @click="$router.back()"
                class="btn btn-secondary font-weight-bolder font-size-sm"
              >
                Batal</span
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <b-form-group
              id="input-group-1"
              label="Nama Kelas:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Kelas"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentKelasForm.body.class_name"
                  type="text"
                  placeholder="Masukkan Nama Kelas"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Subject:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Subject"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentKelasForm.body.subject"
                  type="text"
                  placeholder="Masukkan Subject"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  STORE_CLASS,
  ASSIGN_CLASS,
  DETAIL_CLASS,
  UPDATE_CLASS,
} from "@/core/services/store/kelas.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "KelasForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      currentKelasForm: {
        body: {},
      },
      isLoading: false,
      isCreate: true,
      editorOption: {
        // Some Quill options...
      },
      currentTeacherForm: {
        class_id: {},
        teachers_id: [],
      },
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Nama Guru",
          sortable: true,
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    if (this.idKelas) {
      this.getData();
    }
  },
  methods: {
    submitFormKelas() {
      this.$refs.formKelas.validate().then((success) => {
        console.log(success);
        if (success) {
          const _data = JSON.parse(JSON.stringify(this.currentKelasForm));
          this.$store
            .dispatch(this.idKelas ? UPDATE_CLASS : STORE_CLASS, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.idKelas ? "ubah" : "buat"} Kelas`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/kelas`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.idKelas ? "Ubah" : "Membuat"} Kelas`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    check: function (data) {
      const item = data.item._id;

      const isExist =
        this.currentTeacherForm.teachers_id.filter((elem) => elem == item)
          .length > 0;

      if (isExist) {
        this.currentTeacherForm.teachers_id =
          this.currentTeacherForm.teachers_id.filter((elem) => {
            elem != item;
          });
      } else {
        this.currentTeacherForm.teachers_id.push(item);
      }
    },
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map((item) => {
        return item.kelas.nama_kelas;
      });
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length;
      item.currentPage = 1;
    },
    getData() {
      this.$store
        .dispatch(DETAIL_CLASS, this.idKelas)
        .then((response) => {
          this.isLoading = false;
          const data = response.data;

          this.currentKelasForm = {
            class_id: data._id,
            body: {
              class_name: data.class_name,
              subject: data.subject,
            },
          };
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Data Kelas`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() {},
  },
  computed: {
    ...mapState({
      errors: (state) => state.classModule.errors,
    }),
    ...mapGetters(["currentUserList"]),
    idKelas() {
      return this.$route.params.idKelas;
    },
    isType() {
      return this.$route.params.type;
    },
  },
};
</script>
